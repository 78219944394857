import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const RealEstate = () => (
  <Layout>
    <SEO title="Real Estate" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Real Estate</h1>
        <p>
          If you need to lease a single location or need help managing a
          regional portfolio of leased sites for telecom, power, or physical
          properties, then you are finding yourself managing a massive amount of
          transactional and record management data. Our agents are experts in
          helping with site selection, negotiation and succesful acquisitions.
          We pride ourselves on being able to build strong relationships and
          provide professional services based on listening to your goals
          delivering results that exceed your expectations.{" "}
        </p>
        <p>
          We start with a gap analysis of your contractual obiligations to see
          where risks lay. Whether your portfolio includes telecom sites,
          industrial locations, or fee properties, we will work to mangage and
          implement a strategy that best serves your needs. We rely on the
          knowledge and experience of our leadership team and their specialties
          in Law, Real Estate, Negotiaions and Real Estate Acquisition practices
          to best serve your individual and unique needs. Our experts will
          provide confidence that each leasing choice, in fee acquisition or
          asset disposition — supports our client's vision and goals, while
          always promoting strong fiscal stewardship of our client's resources.
        </p>
        <p>
          We leverage years of industry experience to our clients advantage.
        </p>
        <div className="row">
          <div className="col-lg-7">
            <h3 className="my-4">We’re ready to assist you with:</h3>
            <ul>
              <li>Acquisitions, Dispositions, Leasing</li>
              <li>Lease restructuring and audits</li>
              <li>
                Leveraging A.I. to digitally extract data from all of your real
                estate contracts
              </li>
              <li>Market & Demographic Analysis</li>
              <li>Record Management and Project Management</li>
              <li>Subleasing analysis and management</li>
            </ul>
          </div>
          <div className="col-lg-5 mx-auto">
            <img src={hawkeye} className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RealEstate
